import Mock from "../mock";
import axios from 'axios';
//approvedStatus = ["initiated","pending","approved","rejected"]
const candidateList = [
  {
    id: 1,
    name: "Vimala WiseAnt",
    email: "vimala@wiseant.com",
    mobileNumber: "9884412345",
    aadhaarNumber: "705305341234",
    panNumber : "ARTPM4427H",
    uanNumber: "101488541234",
    yearsOfExp : 5,
    approvedStatus : "initiated",
    techStack: "React, Node"
  },
  {
    id: 2,
    name: "Hari Ganesh R",
    email: "hariganesh@wiseant.com",
    mobileNumber: "8637620629",
    aadhaarNumber: "705305341480",
    panNumber : "ARTPM4427H",
    uanNumber: "101488548765",
    yearsOfExp : 7,
    approvedStatus : "approved",
    techStack: "React, .Net"
  }
];


Mock.onGet("/api/candidate/update").reply((config) => {
    // let uid = config.data;
    let response = [];
  
    // if (uid) {
    //   response = getDetailedCartList(uid);
    // }
  
    return [200, response];
  });
  
Mock.onPost("/api/candidate/upload").reply((req, res) => {
    console.log('req hit', req);
    // res.status(200).setHeader('Access-Control-Allow-Origin', '*').json({ name: 'Fake Upload Process' });
  
    return [200, { name: 'Fake Uploaded' }];
});



// Done
Mock.onPost("/api/candidate/addcandidate").reply((config) => {
  try {
    console.log('config', JSON.parse(config.data))
    const dt = JSON.parse(config.data);
    const { name, email, mobileNumber, aadhaarNumber, panNumber, uanNumber, yearsOfExp, techStack} = dt.state;
    
    const candidate = candidateList.find((u) => u.mobileNumber === mobileNumber);
    console.log('email', email, dt )
    if (candidate) return [200, { message: "Candidate already exists!" }];

    const newCandidate = {
      id: candidateList.length + 1,
      name: name,
      email: email,
      mobileNumber: mobileNumber,
      aadhaarNumber: aadhaarNumber,
      panNumber:panNumber,
      uanNumber:uanNumber,
      yearsOfExp:yearsOfExp,
      approvedStatus: "",
      techStack: techStack
    };
    console.log('newCandidate', newCandidate);
    candidateList.push(newCandidate);
    localStorage.setItem('candidates',JSON.stringify(candidateList));
    const payload = { candidates: candidateList };
    return [200, payload];
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});
// Done
Mock.onGet("/api/candidate/getlist").reply((req, res) => {
  try {
    // localStorage.removeItem('candidates');
    const candidates = JSON.parse(localStorage.getItem('candidates', null, -1));
    if (candidates == null) {
      localStorage.setItem('candidates',JSON.stringify(candidateList));
      const payload = { candidates: candidateList };
      return [200, payload];
    }
    else{
      // let _candidate = JSON.parse(candidates);
      // console.log("_candidate", JSON.parse(localStorage.getItem('candidates', null, -1)));
      const payload = { candidates: candidates };
      return [200, payload];
    }
    
  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});

// Mock.onGet("/api/candidate/getbyid").reply((config) => {
//   try {
//     let cid = config.data;
//     // localStorage.removeItem('candidates');
//     const candidates = JSON.parse(localStorage.getItem('candidates', null, -1));
//     if (candidates == null) {
//       localStorage.setItem('candidates',JSON.stringify(candidateList));
//       const payload = { candidates: candidateList };
//       return [200, payload];
//     }
//     else{
//       // let _candidate = JSON.parse(candidates);
//       // console.log("_candidate", JSON.parse(localStorage.getItem('candidates', null, -1)));
//       const payload = { candidates: candidates };
//       return [200, payload];
//     }
    
//   } catch (err) {
//     console.error(err);
//     return [500, { message: "Internal server error" }];
//   }
// });

const getDetailedById = (cid) => {
  const candidates = JSON.parse(localStorage.getItem('candidates', null, -1)) ?? candidateList;
  let response = candidates.find((u) => u.id == cid);
  return response ?? null;
};
// Done
Mock.onGet("/api/candidate/getbyid").reply((config) => {
  let cid = config?.params?.cid ;
  // console.log('getbyid', config, cid);
  let response = [];
  if (cid) {
    response = getDetailedById(cid);
  }
  return [200, response];
});

Mock.onGet("/api/candidate/report/getbyid").reply((config) => {
  let cid = config?.params?.cid ;
  let cnumber = config?.params?.cnumber;
  // console.log('getbyid', config, cid, cnumber);
  let response = [];
  if (cid) {
    response = getDetailedById(cid);
  }
  return [200, response];
  // (async () => {
  //   // *https://www.registers.service.gov.uk/registers/country/use-the-api*
  //   const response = await axios.get(
  //     "https://dummy.restapiexample.com/api/v1/employee/1"
  //   );
  //   await sleep(200); // For demo purposes.
  //   console.log("data from 2nd req", response);
    
  //   // if (cid) {
  //   //   response = getDetailedById(cid);
  //   // }
  //   return [200, response];
  // })();
  
});
function sleep(delay = 0) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

Mock.onGet("/api/candidate/getdocuments").reply((config) => {
  let cPanNumber = config?.params?.cPanNumber ;
  // console.log('getdocuments', config,cPanNumber);
  let docsList = JSON.parse(localStorage.getItem(cPanNumber, null, -1)) ?? null;

  if(docsList != null){
    const order = ["resume", "aadhaar", "pan", "payslip", "form26", "uan"];
    docsList = docsList.sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type));
    return [200, docsList];
  }
  else return [200, docsList];
});

// Done
Mock.onPost("/api/candidate/adddocuments").reply((config) => {
  try {
    const dt = JSON.parse(config.data);
    const { name, type, fileName, panNumber } = dt.requestData;

    const docsList = JSON.parse(localStorage.getItem(panNumber, null, -1));
    
    const jsonData = {
      name: name,
      type: type,
      fileName: fileName
    };
    if (docsList == null) {
      localStorage.setItem(panNumber,JSON.stringify([jsonData]));
      const payload = { docsList: [jsonData] };
      return [200, payload];
    }
    else{
      let response = [];
      const isExist = docsList.find((u) => u.name == name);
      if(isExist == null){
        response = [...docsList, jsonData];
      }
      else response = [...docsList];
      localStorage.removeItem(panNumber);
      localStorage.setItem(panNumber,JSON.stringify(response));
      const payload = { docsList: response };
      return [200, payload];
    }

  } catch (err) {
    console.error(err);
    return [500, { message: "Internal server error" }];
  }
});